
import { defineComponent, ref } from 'vue'
import FlowStep from '@/components/shared/templates/FlowStep.vue'
import TmButton from '@/components/shared/TmButton.vue'

export default defineComponent({
  name: 'AuthAppStep',
  components: {
    TmButton,
    FlowStep,
  },
  props: {
    title: {
      type: String,
      default: 'Configure authentication app',
    },
    editButton: {
      type: String,
      default: 'Configure app',
    },
  },
  setup() {
    const cantScanCode = ref(false)

    return {
      cantScanCode,
    }
  },
})
