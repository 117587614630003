
import { defineComponent, ref } from 'vue'
import FlowStep from '@/components/shared/templates/FlowStep.vue'
import TmTilesSwitch from '@/components/shared/TmTilesSwitch.vue'

export default defineComponent({
  name: 'SelectAuthMethodStep',
  components: {
    TmTilesSwitch,
    FlowStep,
  },
  props: {
    title: {
      type: String,
      default: 'Select authentication method',
    },
    editButton: {
      type: String,
      default: 'Edit',
    },
  },
  emits: ['update-method'],
  setup() {
    const methods = [
      {
        type: 'authApp',
        icon: 'tmi-phone-lock',
        title: 'Authentication app (recommended)',
        description: 'Retrieve codes from Google Authenticator app on your device.',
        badge: {
          label: 'Recommended',
          color: 'success',
          outline: true,
        },
      },
      {
        type: 'sms',
        icon: 'phone',
        title: 'Phone number',
        description: 'Receive a text message or a call to your mobile device when signing in.',
      },
    ]
    const authMethodValue = ref(methods[0])

    return {
      authMethodValue,
      methods,
    }
  },
})
