
import { defineComponent, ref } from 'vue'
import FlowStep from '@/components/shared/templates/FlowStep.vue'
import BackupCodes from '@/components/shared/templates/BackupCodes.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import { useBreakpoints } from '@/compositions/breakpoints'
import { codesNoUsed } from '@/definitions/_general/_data/backupCodes'

export default defineComponent({
  name: 'TwoFaEnabledStep',
  components: {
    TmButton,
    TmTooltip,
    BackupCodes,
    FlowStep,
  },
  props: {
    title: {
      type: String,
      default: 'Two-factor authentication enabled',
    },
    authMethod: {
      type: String,
    },
  },
  setup() {
    const { isSmMax } = useBreakpoints()
    const codes = ref(codesNoUsed)
    const copiedStatus = ref(false)
    const copyCodes = () => {
      copiedStatus.value = true
    }

    return {
      isSmMax,
      codes,
      copiedStatus,
      copyCodes,
    }
  },
})
