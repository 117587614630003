
import { defineComponent, ref } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import FlowStepList from '@/components/shared/templates/FlowStepList.vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import SelectAuthMethodStep from '@/components/pages/admin/workspace/security/twoFaSteps/SelectAuthMethodStep.vue'
import AuthAppStep from '@/components/pages/admin/workspace/security/twoFaSteps/AuthAppStep.vue'
import EnterVerificationCodeStep from '@/components/pages/admin/workspace/security/twoFaSteps/EnterVerificationCodeStep.vue'
import AddPhoneStep from '@/components/pages/admin/workspace/security/twoFaSteps/AddPhoneStep.vue'
import TwoFaEnabledStep from '@/components/pages/admin/workspace/security/twoFaSteps/TwoFaEnabledStep.vue'

export default defineComponent({
  name: 'TwoFactorAuthenticationFlow',
  components: {
    PageContent,
    FlowStepList,
    DetailsHero,
    TwoFaEnabledStep,
    AddPhoneStep,
    EnterVerificationCodeStep,
    AuthAppStep,
    SelectAuthMethodStep,
  },
  setup() {
    const breadcrumbs = [
      { label: 'Admin', name: 'base.admin' },
      { label: 'Workspace security', name: 'base.admin.workspaceSecurity' },
      { label: 'Two-factor authentication' },
    ]
    const authMethodValue = ref('authApp')
    const authenticationMethod = ref('authentication-app')

    const currentStep = ref(1)
    const onStepChange = (value: number) => {
      currentStep.value = value
    }

    return {
      breadcrumbs,
      authMethodValue,
      authenticationMethod,
      currentStep,
      onStepChange,
    }
  },
})
