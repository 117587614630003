
import { defineComponent } from 'vue'
import FlowStep from '@/components/shared/templates/FlowStep.vue'
import CodeConfirmation from '@/components/pages/auth/CodeConfirmation.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmCountdown from '@/components/shared/TmCountdown.vue'

export default defineComponent({
  name: 'EnterVerificationCodeStep',
  components: {
    TmButton,
    TmCountdown,
    CodeConfirmation,
    FlowStep,
  },
  props: {
    title: {
      type: String,
      default: 'Enter verification code',
    },
    continueButton: {
      type: String,
      default: 'Verify code and enable 2FA',
    },
    authMethod: {
      type: String,
    },
    hideRecend: {
      type: Boolean,
    },
  },
  emits: ['step-back'],
})
