import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_details_hero = _resolveComponent("details-hero")!
  const _component_select_auth_method_step = _resolveComponent("select-auth-method-step")!
  const _component_auth_app_step = _resolveComponent("auth-app-step")!
  const _component_add_phone_step = _resolveComponent("add-phone-step")!
  const _component_enter_verification_code_step = _resolveComponent("enter-verification-code-step")!
  const _component_two_fa_enabled_step = _resolveComponent("two-fa-enabled-step")!
  const _component_flow_step_list = _resolveComponent("flow-step-list")!
  const _component_page_content = _resolveComponent("page-content")!

  return (_openBlock(), _createBlock(_component_page_content, {
    size: "large",
    "bg-shade": "",
    breadcrumbs: _ctx.breadcrumbs
  }, {
    "filter-bar-slot": _withCtx(() => [
      _renderSlot(_ctx.$slots, "filter-bar-slot")
    ]),
    "filter-bar-dropdown": _withCtx(() => [
      _renderSlot(_ctx.$slots, "filter-bar-dropdown")
    ]),
    before: _withCtx(() => [
      _renderSlot(_ctx.$slots, "before")
    ]),
    default: _withCtx(() => [
      _createVNode(_component_details_hero, {
        icon: "verified_user",
        title: "Set up two-factor authentication",
        class: "mb-4"
      }),
      _createVNode(_component_flow_step_list, null, {
        default: _withCtx(({ currentStep, onStepChange }) => [
          _createVNode(_component_select_auth_method_step, {
            step: 1,
            "current-step": currentStep,
            "hide-edit-button": currentStep === 4,
            onUpdateMethod: _cache[0] || (_cache[0] = ($event: any) => (_ctx.authMethodValue = $event)),
            onStepChange: onStepChange
          }, null, 8, ["current-step", "hide-edit-button", "onStepChange"]),
          (_ctx.authMethodValue === 'authApp')
            ? (_openBlock(), _createBlock(_component_auth_app_step, {
                key: 0,
                step: 2,
                "current-step": currentStep,
                "hide-edit-button": currentStep === 4,
                onStepChange: onStepChange
              }, null, 8, ["current-step", "hide-edit-button", "onStepChange"]))
            : (_openBlock(), _createBlock(_component_add_phone_step, {
                key: 1,
                step: 2,
                "current-step": currentStep,
                "hide-edit-button": currentStep === 4,
                onStepChange: onStepChange
              }, null, 8, ["current-step", "hide-edit-button", "onStepChange"])),
          _createVNode(_component_enter_verification_code_step, {
            step: 3,
            "current-step": currentStep,
            "auth-method": _ctx.authMethodValue,
            "hide-edit-button": currentStep === 4,
            "hide-recend": _ctx.authMethodValue === 'authApp',
            onStepChange: onStepChange,
            onStepBack: ($event: any) => (onStepChange(2))
          }, null, 8, ["current-step", "auth-method", "hide-edit-button", "hide-recend", "onStepChange", "onStepBack"]),
          _createVNode(_component_two_fa_enabled_step, {
            step: 4,
            "current-step": currentStep,
            "auth-method": _ctx.authMethodValue
          }, null, 8, ["current-step", "auth-method"])
        ]),
        _: 1
      })
    ]),
    _: 3
  }, 8, ["breadcrumbs"]))
}