
import { defineComponent, ref } from 'vue'
import FlowStep from '@/components/shared/templates/FlowStep.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'

export default defineComponent({
  name: 'AddPhoneStep',
  components: {
    TmFormLine,
    FlowStep,
  },
  props: {
    title: {
      type: String,
      default: 'Add phone number',
    },
    editButton: {
      type: String,
      default: 'Change number',
    },
    continueButton: {
      type: String,
      default: 'Add phone number',
    },
  },
  setup() {
    const phoneNumber = ref('(243) 542-5436')

    return {
      phoneNumber,
    }
  },
})
